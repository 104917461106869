<template>
  <div class="root">
    <div class="head">
      <button @click="descargar">Descargar</button>
      <select @change="limites" v-model="preferencias.registros[tabla]">
        <option>50</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
      </select>
      Viendo {{numero}} registros de {{preferencias.largos[tabla]}}
      <button @click="mas">Ver {{preferencias.registros[tabla]}} más</button>
    </div>
    <div class="container">
      <Tabla ref="tabla" tabla='obras' tabla_preferencias="obras" :filtrable="true"/>
    </div>
  </div>
  
</template>

<script>
import Tabla from '@/components/Tabla.vue'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'Obras',
    data: function () {
      return {
        tabla: 'obras',
        numero: null
      }
    },
    computed:{
      ...mapState(['preferencias','cache'])
    },
    components: {
        Tabla
    },
    methods:{
      ...mapActions(['api']),
      limites(){
        this.$refs.tabla.filtrar()
        this.api([{'campo':'REGISTROS','valor':this.preferencias.registros[this.tabla]},{'campo':'tipo','valor':'guardar'},{'campo':'TABLA','valor':this.tabla.toUpperCase()},{'campo':'USUARIO','valor':this.cache.user},'preferencias'])
      },
      mas(){
      this.$refs.tabla.ver_mas()
    },
      descargar(){
      this.exportCSVFile(null,this.$refs.tabla.filtrado,'descargar')
    },
    convertToCSV(objArray) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let line = "";
    for (let i = 0; i < this.$refs.tabla.campos.length; i++) {
        if (line != "") line += ";";
        line +=  '"'+this.$refs.tabla.campos[i].titulo+'"';
    }
    str += line + "\r\n";
    for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
            if (line != "") line += ";";
            line += '"' + array[i][index] + '"';
        }
        str += line + "\r\n";
    }
    return str;
    },
    exportCSVFile(headers, items, fileName) {
    if (headers) {
     items.unshift(headers);
    }
      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV(jsonObject);
      const exportName = fileName + ".csv" || "export.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
        } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
     }
    }
    }
    }
}
</script>

<style scoped>
.head{
  flex-grow: 0;
  color: white;
  width: 100%;
  background-color: rgb(255, 172, 116);
}
.container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.container > div{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.root{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>